<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("ScanCamera.title") }}</h1>
      </div>
    </div>
    <hr />
    <br />
    <div class="section-action">
      <v-row>
        <v-col cols="12" md="3" lg="3">
          <label class="label-input">{{ $t("ScanCamera.search_title") }}</label>
          <v-text-field
            class="btn btn-color btn-border btn-border-color"
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('Search.search')"
            v-model="employee_name"
            @keypress.enter="fetchScanCamera"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <div class="mt-5">
            <v-btn class="ma-2 lfont btn" color="info" @click="onCreate">
              {{ $t("AddNew") }}
            </v-btn>
          </div>
        </v-col>
        <!-- <v-col cols="12" md="4" lg="4">
          <label class="label-input">{{ $t("ScanPoint.dept") }}</label>
          <v-select
            class="btn btn-color btn-border btn-border-color"
            outlined
            dense
            placeholder="ເລືອກພະແນກ ..."
            hide-details
            :items="listDepartment"
            item-value="id"
            item-text="name"
            v-model="department_id"
            @change="fetchScanCamera"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <label class="label-input">{{ $t("ScanPoint.branch") }}</label>

          <v-select
            class="btn btn-color btn-border btn-border-color"
            outlined
            dense
            placeholder="ເລືອກສາຂາ ..."
            hide-details
            :items="listBranch"
            item-value="id"
            item-text="branch_name"
            v-model="branch_id"
            @change="fetchScanCamera"
          >
          </v-select>
        </v-col> -->
      </v-row>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listScanPoint.length > 0">
          <thead>
            <tr>
              <th class="text-left">No</th>
              <th class="text-left">{{ $t("ScanCamera.table.emp_number") }}</th>
              <th class="text-left">{{ $t("ScanCamera.table.emp_name") }}</th>
              <th class="text-left">{{ $t("ScanCamera.table.dept") }}</th>
              <th class="text-left">
                {{ $t("ScanCamera.table.scan_camera") }}
              </th>

              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listScanPoint" :key="idx">
              <td>{{ idx + 1 }}</td>
              <td>
                {{ item.emp_number }}
              </td>
              <td>{{ item.name }} {{ item.surname }}</td>
              <td>
                {{ item.dept_name }}
              </td>
              <td>{{ item.user_code }}</td>
              <td>
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title
                        class="btn-delete"
                        @click="onDeleted(item)"
                        >{{ $t("action.delete") }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else />

        <Pagination
          class="mt-5 mb-5"
          v-if="pagination.total_pages > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchScanCamera"
        >
        </Pagination>
        <ModalDelete>
          <template v-slot="{ close }">
            <Delete :item="item" @close="close" @success="fetchScanCamera()" />
          </template>
        </ModalDelete>
        <Loading v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Paginate/Pagination";
import Loading from "@/components/Loading";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import Delete from "@/views/MainCompany/ScanCamera/Delete";

export default {
  components: {
    Pagination,
    Loading,
    defaultTableNoResult,
    Delete,
  },
  data() {
    return {
      employeeScanPoint: [],
      employee: "",
      dialog: false,
      model: null,
      offset: 10,
      pagination: {},
      per_page: 10,

      isLoading: true,
      listScanPoint: [],
      department: {},
      departmentId: "",
      listDepartment: [],
      department_id: "",
      listBranch: [],
      branch_id: "",
      employee_name: "",
      item: {},
    };
  },
  methods: {
    onDeleted(item) {
      this.item = item;
      this.$store.commit("modalDelete_State", true);
    },
    deleteScanPoint(id) {
      this.isLoading = true;
      this.$axios
        .delete(`company/employee-scan-point/${id}`)
        .then((res) => {
          this.isLoading = false;
          this.fetchScanCamera();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchFilterItem() {
      this.fetchScanCamera();
    },
    filterScanPoint(id) {
      return (
        this.listScanPoint.filter((item) => {
          return item.id == id;
        })[0] || {}
      );
    },
    onCreate() {
      this.$router
        .push({
          name: "scan_camera.create",
        })
        .catch(() => {});
    },

    fetchScanCamera() {
      this.isLoading = true;
      this.$axios
        .get(`company/list/employee/clockIn/camera`, {
          params: {
            filter: this.employee_name,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listScanPoint = res.data.data;
            /* this.pagination = res.data.data.pagination;
            if (!this.listScanPoint.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            } */
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    /* fetchDepartment() {
      this.$axios.get(`company/list/departments/selected`, {}).then((res) => {
        if (res.status === 200) {
          this.listDepartment = res.data.data;
        }
      });
    },
    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, */
  },
  created() {
    this.fetchScanCamera();
    /* this.fetchDepartment();
    this.fetchBranch(); */
  },
};
</script>

<style scoped lang="scss">
.scan-point {
  background: rgb(68, 135, 241) !important;
  color: white !important;
  margin: 4px;
  padding: 5px;
  font-size: 12px;
  border-radius: 25px;
  a {
    color: rgb(216, 49, 49);
  }
}
</style>
