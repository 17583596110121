<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("scanTime.caption") }}</h1>
      </div>
    </div>
    <hr />
    <br />
    <div class="section-action">
      <v-row>
        <v-col cols="12" md="3" lg="3">
          <label class="label-input">{{ $t("ScanPoint.search_title") }}</label>
          <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense
            prepend-inner-icon="mdi-magnify" :placeholder="$t('Search.search')" v-model="searchItem"
            @keypress.enter="fetchScanTime">
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <label class="label-input">{{ $t("ScanPoint.dept") }}</label>
          <v-select class="btn btn-color btn-border btn-border-color" outlined dense :placeholder="$t('Employee.dept')"
            hide-details :items="listDepartment" item-value="id" item-text="name" v-model="department_id"
            @change="fetchScanTime">
          </v-select>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <label class="label-input">{{ $t("ScanPoint.branch") }}</label>

          <v-select class="btn btn-color btn-border btn-border-color" outlined dense :placeholder="$t('Employee.branch')"
            hide-details :items="listBranch" item-value="id" item-text="branch_name" v-model="branch_id"
            @change="fetchScanTime">
          </v-select>
        </v-col>
      </v-row>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listScanTime.length > 0">
          <thead>
            <tr>
              <th class="text-left">No</th>
              <th class="text-left">{{ $t("scanTime.table.emp_number") }}</th>
              <th class="text-left">{{ $t("scanTime.table.emp_name") }}</th>
              <th class="text-left">{{ $t("scanTime.table.dept") }}</th>
              <th class="text-left">{{ $t("scanTime.table.branch") }}</th>
              <th class="text-left">
                {{ $t("scanTime.table.schedule_name") }}
              </th>
              <!-- <th class="text-left">
                {{ $t("scanTime.table.schedule_time") }}
              </th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listScanTime" :key="idx">
              <td>{{ idx + 1 }}</td>
              <!-- {{ pagination.current_page * 10 - 10 + idx + 1 }} -->
              <td>
                {{ item.emp_number }}
              </td>
              <td>{{ item.name }} {{ item.surname }}</td>
              <td>
                {{ item.dept }}
              </td>
              <td>
                {{ item.branch }}
              </td>
              <td>
                <ul v-for="(clock_time, idx) in item.clock_time" :key="idx"
                  :class="clock_time.status == 'active' ? 'shift-item-active' : 'shift-item-expire'">
                  <div>
                    <p :style="[clock_time.status == 'active' ? { color: 'green' } : { color: 'red' }]">
                      <v-icon>mdi-clock-edit</v-icon> {{ clock_time.name }} ({{ clock_time.status
                      }})
                      <v-btn icon color="pink" @click="editShift(clock_time)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </p>
                    <v-icon>mdi-calendar-range</v-icon>
                    <span class="text-danger">{{ clock_time.started_at }}</span> ->
                    <span class="text-danger">{{ clock_time.ended_at ?
          clock_time.ended_at
          :
          $t("scanTime.now") }}</span>
                  </div>

                  <li v-for="(scan_time, idx) in clock_time.scan_time" :key="idx"> {{ scan_time.name }}
                    <span style="color:rgb(0, 60, 255);">
                      {{ scan_time.check_in_before }} -
                      {{ scan_time.check_out_after }}
                    </span>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else />

        <Pagination class="mt-5 mb-5" v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
          @paginate="fetchScanTime">
        </Pagination>
        <Loading v-if="isLoading" />
        <ModalUpdate :dialog="dialog_update_shift" :update_shift_item="update_shift_item"
          @close="dialog_update_shift = false" @success="fetchScanTime" @submit="isLoading = true" />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Paginate/Pagination";
import Loading from "../../../components/Loading";
import defaultTableNoResult from "../../../components/defaultTableNoResult";
import addNew from "@/views/MainCompany/ScanPoint/layouts/addNew";
import ModalUpdate from './ModalUpdate.vue'

export default {
  components: {
    Pagination,
    Loading,
    defaultTableNoResult,
    addNew,
    ModalUpdate,
  },
  data() {
    return {
      employeeScanPoint: [],
      employee: "",
      dialog_update_shift: false,
      update_shift_item: {},
      model: null,
      offset: 10,
      pagination: {},
      per_page: 10,

      isLoading: true,
      listScanTime: [],
      department: {},
      departmentId: "",
      searchItem: "",
      listDepartment: [],
      department_id: "",
      listBranch: [],
      branch_id: "",
    };
  },
  methods: {
    editShift(item) {
      this.dialog_update_shift = true;
      this.update_shift_item = item;
    },
    searchFilterItem() {
      this.fetchScanTime();
    },
    filterScanPoint(id) {
      return (
        this.listScanTime.filter((item) => {
          return item.id == id;
        })[0] || {}
      );
    },

    fetchScanTime() {
      this.isLoading = true;
      this.$axios
        .get(`company/employee-scan-time`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
            department_id: this.department_id,
            branch_id: this.branch_id,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listScanTime = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listScanTime.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    fetchDepartment() {
      this.$axios
        .get(`company/list/departments/selected`, {})
        .then((res) => {
          if (res.status === 200) {
            this.listDepartment = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.fetchScanTime();
    this.fetchDepartment();
    this.fetchBranch();
  },
};
</script>

<style scoped lang="scss">
.shift-item-active {
  border: 2px solid rgb(0, 245, 4);
  border-radius: 5px;
}

.shift-item-expire {
  border: 2px solid rgb(255, 0, 0);
  border-radius: 5px;
}

.scan-point {
  background: rgb(68, 135, 241) !important;
  color: white !important;
  margin: 4px;
  padding: 5px;
  font-size: 12px;
  border-radius: 25px;

  a {
    color: rgb(216, 49, 49);
  }
}
</style>
